const IS_COMPANY_SLUG_SPECIAL = window.location.pathname.split("/")[1] === "49ebf1380171";
const IS_COMPANY_SLUG_SPECIAL_OOTUKENSETU = window.location.pathname.split("/")[1] === "fab551458855";
const OUTSOURCE_UNIT_COST_TYPE_NAME = "外注費";
const RADIO_BUTTON_BUDGET_AMOUNT = "amount";
const RADIO_BUTTON_BUDGET_CONSTUNIT = "constunit";
const RADIO_BUTTON_BUDGET_UNIT = "unit";
const COST_TYPE_BUDGET = "ProjectCostType";
const TASK_BUDGET = "Task";
const GROUP_BUDGET = "Group";
const PROJECT_UNIT_COST_OPTION = [
  { value: "単価データを新しく追加する", label: "単価データを新しく追加する" },
  { value: "外注先の契約単価を追加する", label: "外注先の契約単価を追加する" },
  { value: "請負等の比率単価を追加する", label: "請負等の比率単価を追加する" },
];

const QUERY_PARAMS = {
  by_project_supplier: "project_supplier",
  by_unit_cost_category: "unit_cost_category",
};

const DAILY_REPORTS = {
  unit_price: "unit_price",
  daily_report_entry: "daily_report_entry",
  loss_and_profit: "loss_and_profit",
  amount: "amount",
  show_detail: "show_detail",
  today_unit_cost: "today_unit_cost",
  cumulative_unit_cost: "cumulative_unit_cost",
};

const DATE_FORMAT = {
  JAPANESE: "YYYY年MM月DD日",
  JAPANESE_HMS: "YYYY年MM月DD日 HH:mm:ss",
  ENGLISH: "YYYY/MM/DD",
  WEEK_DAY: "dd",
  MONTH_DAY_EN: "MM/DD",
  MONTH_DAY_JP: "M月D",
  MONTH_YEAR_JP: "YYYY年MM月",
  YEAR: "YYYY",
};

const CURRENT_DATE_FORMAT = {
  JAPANESE: "YYYY年MM月DD日",
  ENGLISH: "YYYY-MM-DD",
};

const UN_GROUP_CONFIRM = {
  MESSAGE_ALL:
    "全ての作業グループを解除します。<br>既に入力されている日報データも失われます。<br>よろしいですか？",
  MESSAGE_WITH_DAILY_REPORT:
    "選択された作業グループを解除します。<br>既に入力されている日報データも失われます。<br>よろしいですか？",
  MESSAGE_WITHOUT_DAILY_REPORT: "選択された作業グループを解除します。<br>よろしいですか？",
  MESSAGE_AGAIN:
    "全ての作業グループを解除すると、既に入力されている日報データも失われます。<br>この操作は取り消せません。<br>本当によろしいですか？",
  YES: "解除する",
  NO: "キャンセル",
};

const DELETE_TASK_CONFIRM = {
  MESSAGE: "この工種を削除しますか？",
  YES: "削除",
  NO: "キャンセル",
};

const COPY_GROUP_CONFIRM = {
  MESSAGE: "選択された作業グループをコピーします。よろしいですか？",
  YES: "コピー",
  NO: "キャンセル",
};

const EXECUTRE_BUGGET = {
  UNAPPROVE_CONFIRM: "承認を取り消しますか？",
  APPROVE_CONFIRM: "実行予算を承認しますか？",
  YES: "はい",
  NO: "いいえ",
};

const MODE = {
  ACTUAL: "actual",
  PLAN: "plan",
  ACTUAL_CLASS: "Result",
  PLAN_CLASS: "Plan",
};

const UNIT = {
  CURRENCY: "円",
  DAY: "日",
};
const LOCAL_STORES_KEY = {
  RESOURCE: "new-resource",
  DR_RESOURCE: "new-dr-resource",
  PROJECT_ID: "project-id",
  COPY_TASK_ID: "copy-task-id",
  PROJECT_PATTERN_RESOURCE: "new-project-pattern-resource",
  MULTIPLE_PATTERN_RESOURCE: "new-multiple-pattern-resource",
  USER_PATTERN_RESOURCE: "new-user-pattern-resource",
  COMPANY_PATTERN_RESOURCE: "new-company-pattern-resource",
  WORKLIST_RELOAD: "worklist-reload",
  WORKMAIN_RELOAD: "workmain-reload",
  WORKMAIN_DAILYREPORT_RELOAD: "workmain-dailyreport-reload",
  MONTHLY_PERFORMANCES_RELOAD: "monthly-performances-reload",
  SELECTED_GROUP_IDS: "selected-groups-ids",
};

const COMPANY_MASTER_DATA = {
  COST_TYPES: ["間接工事費", "直接工事費"],
  UNIT_COST_TYPES: ["材料費", "労務費", "外注費", "経費", "車輌機械費"],
  UNIT_COST_TYPES_SPECIAL: [
    "機械費",
    "運搬費",
    "労務費",
    "外注費",
    "材料費",
    "燃料費",
    "消耗機材費",
    "修理費",
    "遠隔地経費",
    "その他経費",
  ],
};

const COMPANY_SLUG = {
  SLUG_SPECIAL: "49ebf1380171",
};

const COMPANY_SLUG_OOTUKENSETU = {
  SLUG_SPECIAL: "fab551458855",
};

const MACHINERY = {
  LIST_SPECIAL: ["機械費", "運搬費"],
  LIST_NORMAL: ["車輌機械費"],
};

const CELL_TYPE = {
  INPUT: "input",
  RESTRICTION_INPUT: "restrictionInput",
  ICON: "icon",
  BUTTON: "button",
  RESTRICTION_BUTTON: "restrictionButton",
  CHECKBOX: "checkbox",
  RESTRICTION_CHECKBOX: "restrictionCheckbox",
  SELECT: "select",
  RESTRICTION_SELECT: "restrictionSelect",
  SELECT_TAGGABLE: "selectTaggable",
  RESTRICTION_SELECT_TAGGABLE: "restrictionSelectTaggable",
  SELECT_BY_ROW: "selectByRow",
  RESTRICTION_SELECT_BY_ROW: "restrictionSelectByRow",
};

const OPTIONS = {
  ALL: { label: "全て", value: "" },
};

const CONFIRM_EXECUTION_BUDGET_ESTIMATE = {
  MESSAGE: "実行予算を上書きしますが、よろしいでしょうか？",
  YES: "はい",
  NO: "いいえ",
};

const CONFIRM_CHANGE_UNIT_PRICE_UNIFORMLY = {
  MESSAGE: "全ての単価データを更新しますが、よろしいでしょうか？",
  YES: "はい",
  NO: "いいえ",
};

const CONFIRM_CHANGE_COST_SETTING_LABOR = {
  MESSAGE: "全ての労務費の単価データを更新しますが、よろしいでしょうか？",
  YES: "はい",
  NO: "いいえ",
};

const CONFIRM_CHANGE_COST_SETTING_MACHINE = {
  MESSAGE: "全ての機械車輌費の単価データを更新しますが、よろしいでしょうか？",
  YES: "はい",
  NO: "いいえ",
};

const DIALOG_PS_GROUP_CONFIRMATION = {
  MESSAGE: "工程表には実行予算で登録された「グループ作業」と「工種」が反映されます。",
  YES: "工程表を開く",
};

const CANCEL_CONFIRM = {
  MESSAGE: "本当に解約してもよろしいでしょうか？",
  SAVE_LABEL: "はい",
  SAVE_CLASS: "btn btn-secondary",
  CLOSE_LABEL: "いいえ",
  CLOSE_CLASS: "btn btn-primary btn-default",
};

const CHANGE_EMAIL = {
  MESSAGE: "メールアドレスを変更しますが、よろしいでしょうか？",
  SAVE_LABEL: "はい",
  SAVE_CLASS: "btn btn-secondary",
  CLOSE_LABEL: "いいえ",
  CLOSE_CLASS: "btn btn-primary btn-default",
};

const CHANGE_PASSWORD = {
  MESSAGE: "パスワードを変更しますが、よろしいでしょうか？",
  SAVE_LABEL: "はい",
  SAVE_CLASS: "btn btn-secondary",
  CLOSE_LABEL: "いいえ",
  CLOSE_CLASS: "btn btn-primary btn-default",
};

const ALERT_TEXT = {
  EMPTY_TITLE: " ",
  ORGANIZATION_UNIT_TITLE: " ",
  IMPORT_CSV_TITLE: " ",
  ACCOUNT_CAPABILITY_OPTION_INQUIRY_TITLE: " ",
  ACCOUNT_CAPABILITY_OPTION_INQUIRY_MSG: "ご利用のプランではサポートは使用出来ません。",
  ACCOUNT_CAPABILITY_COMPANY_MASTER_DATA_TITLE: " ",
  ACCOUNT_CAPABILITY_COMPANY_MASTER_DATA_MSG:
    "現在ご利用しているプランでは、会社マスタはご利用になれません。",
  ACCOUNT_CAPABILITY_FEATURE_TITLE: " ",
  ACCOUNT_CAPABILITY_PROJECT_TITLE: " ",
  ENTER_BUDGET_QUANTITY_TITLE: "項目未入力",
  ENTER_BUDGET_QUANTITY_IS_ZERO: "施工単位数量を入力してください。",
  GROUP_QUANTITY_TITLE: "実行予算作成",
  GROUP_QUANTITY_IS_ZERO: "グループの数量が0なので処理出来ません。",
  SELECT_PATTERN: "パターンを選択してください。",
  SAVED: "保存しました。",
  CREATED: "登録しました。",
  UPDATED: "更新しました。",
  ERROR: "文字数制限を超えている項目があります。",
  ERRORS: "何かのエラーが発生しました。",
  ORDER_DETAIL_TEMPLATE_EXPORT: "受注工事明細表を出力しますか？",
  UNIQUENESS_RESOURCE: "外注契約単価に同一の会社名が存在します。1行にまとめて登録してください。",
};

const MONTHLY_PERFORMANCE_CONFIRM = {
  MESSAGE_TITLE:
    "日報で入力された当月の出来高を取り込みますか？<br>※既に入力されている出来高数量は上書きされます。",
  OK: "取込む",
  CANCEL: "キャンセル",
};

const MONTHLY_PERFORMANCE_CLEAR_CONFIRM = {
  MESSAGE_TITLE: "入力したデータをクリアしますか？",
  OK: "クリアする",
  CANCEL: "キャンセル",
};

const COMPANY_EXPENSE_CONFIRM = {
  MESSAGE_IMPORT: "自社経費項目を取込みますか？",
  OK: "取込む",
  CANCEL: "キャンセル",
};

const WORKLIST_CONFIRM = {
  MESSAGE_DELETE: "選択した工事を削除しますか？",
  MESSAGE_AGAIN:
    "削除した工事は復旧できません<br>この操作は取り消せません。<br>本当に選択した工事を削除してよろしいですか？",
  YES: "削除する",
  NO: "キャンセル",
};

const PLAN_CHANGE_CONFIRM = {
  MESSAGE:
    "変更後のプランに合わせて工事データを削除する必要があります。<br>プランの変更を行ってもいいですか？",
  YES: "確認画面へ",
  NO: "キャンセル",
};

const REGEX_FORMAT_NUMBER = /\B(?=(\d{3})+(?!\d))/g;
const REGEX_FORMAT_FLOAT_WITHOUT_0 = /(?!\.)|(?:\.|(\..*?))0+( .+)?$/gm;

const CALCULATION_SIGNS = {
  DIVIDE: "&div;",
  MULTIPLY: "&Cross;",
};

const COST_SETTING_KINDS = {
  MACHINE: "0",
  LABOR: "1",
};

const COST_SETTING_FIELDS = [
  {
    fieldName: "normal_wage_divisor",
    label: "実行予算単価",
    calculationSign: CALCULATION_SIGNS.DIVIDE,
    headerText: "通常",
    defaultValue: 8,
    line: 1,
  },
  {
    fieldName: "overtime_wage_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: "残業",
    defaultValue: 1.25,
    line: 1,
  },
  {
    fieldName: "nighttime_wage_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: "深夜",
    defaultValue: 1.5,
    line: 1,
  },
  {
    fieldName: "holiday_wage_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: IS_COMPANY_SLUG_SPECIAL_OOTUKENSETU ? "法定休日" : "休日",
    defaultValue: 1.25,
    line: 1,
  },
  {
    fieldName: "allowance1_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: "単価1",
    defaultValue: 1,
    fieldLabel: "allowance1_label",
    line: 2,
  },
  {
    fieldName: "allowance2_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: "単価2",
    defaultValue: 1,
    fieldLabel: "allowance2_label",
    line: 2,
  },
  {
    fieldName: "allowance3_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: "単価3",
    defaultValue: 1,
    fieldLabel: "allowance3_label",
    line: 2,
  },
  {
    fieldName: "allowance4_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: "単価4",
    defaultValue: 1,
    fieldLabel: "allowance4_label",
    line: 2,
  },
  {
    fieldName: "allowance5_multiplier",
    label: "通常",
    calculationSign: CALCULATION_SIGNS.MULTIPLY,
    headerText: "単価5",
    defaultValue: 1,
    fieldLabel: "allowance5_label",
    line: 2,
  },
  {
    fieldName: "hourly_cost_divisor",
    label: "実行予算単価",
    calculationSign: CALCULATION_SIGNS.DIVIDE,
    headerText: "通常",
    defaultValue: 8,
    line: 3,
  },
  {
    fieldName: "allowance1_label",
    label: "",
    headerText: "単価1",
    defaultValue: "単価1",
    line: 4,
  },
  {
    fieldName: "allowance2_label",
    label: "",
    headerText: "単価2",
    defaultValue: "単価2",
    line: 4,
  },
  {
    fieldName: "allowance3_label",
    label: "",
    headerText: "単価3",
    defaultValue: "単価3",
    line: 4,
  },
  {
    fieldName: "allowance4_label",
    label: "",
    headerText: "単価4",
    defaultValue: "単価4",
    line: 4,
  },
  {
    fieldName: "allowance5_label",
    label: "",
    headerText: "単価5",
    defaultValue: "単価5",
    line: 4,
  },
];

const COST_SETTING_MODALS = {
  HEADER: "労務費の時間単価設定",
  HEADER_LABOR: "労務費の時間単価設定",
  HEADER_MACHINE: IS_COMPANY_SLUG_SPECIAL
    ? "機械費・運搬費の時間単価設定"
    : "車輌機械費の時間単価設定",
  BODY_DESCRIPTION: "以下の内容で設定されています。",
};

const UNIT_PATTERN = {
  NAME_BLANK_MESSAGE: "パターン名を入力してください。",
  MESSAGE_TITLE_DELETE: "選択中のパターンを削除します。よろしいですか？",
  BUTTON_DELETE_OK: "削除",
  BUTTON_DELETE_CANCEL: "キャンセル",
  COPY_OF: "(のコピー)",
  MESSAGE_TITLE_COPY_COMPANY: "会社マスタの単価パターンを取込みます。よろしいですか？",
  MESSAGE_TITLE_COPY_USER: "個人マスタの単価パターンを取込みます。よろしいですか？",
  BUTTON_COPY_OK: "はい",
  BUTTON_COPY_CANCEL: "いいえ",
  COMPANY_UNIT_COST_CATEGORY: "company_unit_cost_category",
  COMPANY_UNIT_COST: "company_unit_cost",
  USER_UNIT_COST_CATEGORY: "user_unit_cost_category",
  USER_UNIT_COST: "user_unit_cost",
  UNIT_COST_CATEGORY: "unit_cost_category",
  PROJECT_UNIT_COST: "project_unit_cost",
};

const PROJECT_ALERT = {
  ERROR_NOT_FOUND_COST_TYPE: "内訳費目が存在しません。",
  ERROR_NOT_FOUND_UNIT_COST_TYPE: "単価費目が存在しません。",
};

const DR_RESOURCE = {
  MODE_TIME: "time",
  MODE_NORMAL: "normal",
  MACHINERY: "車輌機械費",
  LABOR: "労務費",
};

const MODAL_MAINTAINANCE_COMPANY = {
  TITLE: "全工事更新",
  MESSAGE: "全更新を更新してよろしいですか？",
  TITLE_ROUND_SETTING: "端数処理設定更新",
  MESSAGE_ROUND_SETTING: "全工事の数値が変わります。更新してよろしいですか？",
  SAVE_LABEL: "はい",
  SAVE_CLASS: "btn btn-secondary",
  CLOSE_LABEL: "いいえ",
  CLOSE_CLASS: "btn btn-primary btn-default",
};

const MODAL_MAINTAINANCE_DONE = {
  TITLE: "",
  MESSAGE: "全工事の更新が完了しました。工事一覧画面へ遷移します。",
  OK_LABEL: "はい",
  OK_CLASS: "btn btn-primary btn-default",
};

const PLAN = {
  FREE_PLAN: "free_plan",
  TEAM_PLAN_MONTHLY: "team_plan_monthly",
  TEAM_PLAN_YEARLY: "team_plan_yearly",
  TEAM_PLAN_MONTHLY202204: "team_plan_monthly202204",
  TEAM_PLAN_YEARLY202204: "team_plan_yearly202204",
  PERSONAL_PLAN_MONTHLY: "personal_plan_monthly",
  PROCESS_SHEET_PLAN_MONTHLY: "process_sheet_plan_monthly",
  PROCESS_SHEET_PLAN_YEARLY: "process_sheet_plan_yearly",
};

const COOKIE_KEYS = {
  WORKMAIN_SCROLL_TOP: "workmain_scroll_top",
  WORKMAIN_OPEN_GROUPS: "workmain_open_groups",
  WORKMAIN_DAILYREPORT_SCROLL_TOP: "workmain_dailyreport_scroll_top",
  WORKMAIN_DAILYREPORT_OPEN_GROUPS: "workmain_dailyreport_open_groups",
};

const KEY_DECTECT_FROM_PAGE = "from_page";
const FROM_PAGE_PROJECT_UNIT_PRICE_PATTERN = "project_pattern";
const FROM_PAGE_COMPANY_UNIT_PRICE_PATTERN = "company_pattern";
const FROM_PAGE_USER_UNIT_PRICE_PATTERN = "user_pattern";

export const USER_ROLES = {
  CONTRACT_OWNER: "contract_owner",
  SITE_MANAGER: "site_manager",
};

const LAST_GROUP = 2;

export default {
  MODAL_MAINTAINANCE_COMPANY,
  MODAL_MAINTAINANCE_DONE,
  CONFIRM_CHANGE_COST_SETTING_MACHINE,
  CONFIRM_CHANGE_COST_SETTING_LABOR,
  CONFIRM_CHANGE_UNIT_PRICE_UNIFORMLY,
  CONFIRM_EXECUTION_BUDGET_ESTIMATE,
  EXECUTRE_BUGGET,
  COST_SETTING_MODALS,
  DIALOG_PS_GROUP_CONFIRMATION,
  COST_SETTING_KINDS,
  COST_SETTING_FIELDS,
  OUTSOURCE_UNIT_COST_TYPE_NAME,
  RADIO_BUTTON_BUDGET_AMOUNT,
  RADIO_BUTTON_BUDGET_CONSTUNIT,
  RADIO_BUTTON_BUDGET_UNIT,
  COST_TYPE_BUDGET,
  TASK_BUDGET,
  GROUP_BUDGET,
  PROJECT_UNIT_COST_OPTION,
  QUERY_PARAMS,
  DAILY_REPORTS,
  DATE_FORMAT,
  UN_GROUP_CONFIRM,
  DELETE_TASK_CONFIRM,
  COPY_GROUP_CONFIRM,
  MODE,
  UNIT,
  LOCAL_STORES_KEY,
  COMPANY_MASTER_DATA,
  OPTIONS,
  CANCEL_CONFIRM,
  CHANGE_EMAIL,
  CHANGE_PASSWORD,
  ALERT_TEXT,
  REGEX_FORMAT_NUMBER,
  REGEX_FORMAT_FLOAT_WITHOUT_0,
  CELL_TYPE,
  MONTHLY_PERFORMANCE_CONFIRM,
  MONTHLY_PERFORMANCE_CLEAR_CONFIRM,
  COMPANY_EXPENSE_CONFIRM,
  CURRENT_DATE_FORMAT,
  WORKLIST_CONFIRM,
  PLAN_CHANGE_CONFIRM,
  CALCULATION_SIGNS,
  UNIT_PATTERN,
  PROJECT_ALERT,
  DR_RESOURCE,
  PLAN,
  COMPANY_SLUG,
  MACHINERY,
  COOKIE_KEYS,
  KEY_DECTECT_FROM_PAGE,
  FROM_PAGE_PROJECT_UNIT_PRICE_PATTERN,
  FROM_PAGE_COMPANY_UNIT_PRICE_PATTERN,
  FROM_PAGE_USER_UNIT_PRICE_PATTERN,
  USER_ROLES,
  LAST_GROUP,
};
